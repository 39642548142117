import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ThemeService } from '@ui/legacy-lib';
import {
  CookiePreference,
  CustomerBranding,
  DigitalContract,
  DigitalContractProperty,
  User
} from '@ui/shared/models';

import {
  getCookiesPreference,
  InitCookiesPreference,
  LocalStorageService,
  OpenCookieSettingsModal,
  SetCookiesPreference,
  WINDOW_REF
} from '@ui/legacy-lib';
import { DigitalContractService } from '@ui/legacy-lib';

import { storageKeys } from 'tenant-pool/config';
import * as fromAppState from 'tenant-pool/+state';
import { ENVIRONMENT_CONFIG } from 'tenant-pool/core';
import { CookieBannerComponent, LoadingSpinnerComponent } from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerInfoLegacyComponent } from 'tenant-pool/components/customer-info-legacy/customer-info-legacy.component';
import { ButtonComponent } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-verify-code-landing',
  templateUrl: './verify-code-landing.component.html',
  styleUrls: ['./verify-code-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CookieBannerComponent,
    AsyncPipe,
    RouterLink,
    TranslateModule,
    CustomerInfoLegacyComponent,
    LoadingSpinnerComponent,
    ButtonComponent
  ],
  standalone: true
})
export class VerifyCodeLandingComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private localStorageService = inject(LocalStorageService);
  private dmvService = inject(DigitalContractService);
  private themeService = inject(ThemeService);
  private windowRef = inject(WINDOW_REF);
  private environment = inject(ENVIRONMENT_CONFIG);

  public loading$: Observable<boolean>;
  public processing$: Observable<boolean>;
  public cookiesPreference: CookiePreference;

  public contract: DigitalContract;
  public actionRequired: boolean;
  public property: DigitalContractProperty;
  public token: string;
  public user: User;
  public customerBranding: CustomerBranding;

  public get customerLogo() {
    return this.customerBranding?.logo?.url || this.property?.customerLogo;
  }

  public get isMobile() {
    return this.environment?.mobile;
  }

  public ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';
    this.store.dispatch(new fromAppState.LoadDigitalContract(this.token));

    this.loading$ = this.store.select(fromAppState.getDigitalContractLoading);

    this.processing$ = combineLatest(
      this.store.select(fromAppState.getProcessApplicationActionState),
      this.store.select(fromAppState.getUserDataLoading)
    ).pipe(map(([processing, loading]) => processing.pending || loading));

    this.store
      .select(fromAppState.getDigitalContract)
      .pipe(
        filter(contract => !!contract),
        untilDestroyed(this)
      )
      .subscribe((contract: DigitalContract) => {
        this.property = contract.property;
        this.contract = contract;

        if (contract.branding) {
          this.customerBranding = contract.branding;
          this.themeService.createTheme(this.customerBranding);
        }

        this.user = this.dmvService.getUser(this.contract.tenantSigners[0]);

        // initially load the preference from LS and enable tracking if configured
        this.store.dispatch(new InitCookiesPreference(this.user));

        this.store
          .select(getCookiesPreference)
          .pipe(
            filter(cookiesPreference => !!cookiesPreference),
            untilDestroyed(this)
          )
          .subscribe(
            cookiesPreference => (this.cookiesPreference = cookiesPreference)
          );

        this.actionRequired = contract.actionRequired;
        this.contract.token = this.token;
      });
  }

  public backToApp() {
    this.themeService.setTheme(this.themeService.getDefaultTheme);
  }

  public verify() {
    this.localStorageService.setItem(
      storageKeys.digitalContract,
      this.contract
    );
    this.store.dispatch(new fromAppState.VerifyCode());
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new SetCookiesPreference(payload, this.user));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(new OpenCookieSettingsModal(payload, true, this.user));
  }
}
