import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Store } from '@ngrx/store';
import { PropertyMatchesFacade } from 'tenant-pool/core/services/property-matches.facade';
import { map, tap } from 'rxjs';
import { MainPageNavigation } from 'tenant-pool/config';
import {
  MarketingType,
  PropertyApplicationStatus,
  PropertyMatchBean
} from '@ui/shared/models';
import { Go } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-property-rented',
  templateUrl: './property-rented.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
  standalone: true
})
export class PropertyRentedComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private propertyMatchesFacade = inject(PropertyMatchesFacade);

  ngOnInit() {
    const propertyId = this.route.snapshot.params.propertyId as string;

    this.propertyMatchesFacade
      .loadPropertyMatchesBeans({ sort: 'id,asc' })
      .pipe(
        map(data => data.nodes),
        map(propertyMatches =>
          propertyMatches.filter(propertyMatch =>
            this.isPropertyRentedOut(propertyId, propertyMatch)
          )
        ),
        tap(data => {
          const path = this.getRedirectPathToPropertyUrl(data);
          this.store.dispatch(new Go({ path }));
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private isPropertyRentedOut(
    propertyId: string,
    propertyMatch: PropertyMatchBean
  ): boolean {
    return (
      propertyMatch?.property.id === propertyId &&
      propertyMatch?.property.marketingType === MarketingType.RENT &&
      propertyMatch?.status === PropertyApplicationStatus.REJECTED
    );
  }

  private getRedirectPathToPropertyUrl(data: PropertyMatchBean[]): string[] {
    return data.length > 0
      ? [MainPageNavigation.EXPOSE, data[0].id]
      : [MainPageNavigation.PROPERTIES, MainPageNavigation.APPLICATIONS];
  }
}
