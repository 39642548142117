import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
  signal
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  AuthTokenService,
  ButtonComponent,
  ButtonTypeEnum
} from '@ui/legacy-lib';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { OpenCompleteGarageModal, ResendVerificationEmail } from '../../+state';
import { UserLogoutSilently } from '../../+state/auth/auth.actions';
import { MainPageNavigation } from '../../config';

@Component({
  selector: 'app-email-verification-pending',
  imports: [TranslateModule, ButtonComponent],
  templateUrl: './email-verification-pending.component.html',
  styleUrl: './email-verification-pending.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVerificationPendingComponent implements OnInit {
  private store = inject(Store);
  private authTokenService = inject(AuthTokenService);
  private location = inject(Location);
  private router = inject(Router);

  justRegistered = input(false, { transform: booleanAttribute });
  showGarageSearchProfileModal = input(false, { transform: booleanAttribute });
  applicationId = input<string>(undefined);
  buttonClicked = signal(false);
  token = signal<string>(undefined);

  ngOnInit() {
    if (this.showGarageSearchProfileModal()) {
      this.store.dispatch(OpenCompleteGarageModal());
    } else if (!this.justRegistered()) {
      this.token.set(this.authTokenService.getToken().access_token);
      this.store.dispatch(new UserLogoutSilently());
    }

    if (this.justRegistered()) {
      if (this.applicationId()) {
        this.location.replaceState(
          this.router
            .parseUrl(
              [MainPageNavigation.EXPOSE, this.applicationId()].join('/')
            )
            .toString()
        );
      } else {
        this.location.replaceState(
          this.router
            .parseUrl(
              [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ].join('/')
            )
            .toString()
        );
      }
    }
  }

  public resendVerificationEmail() {
    this.buttonClicked.set(true);
    this.store.dispatch(new ResendVerificationEmail(this.token()));
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
