import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { customEmailValidator } from 'libs/components/legacy/form/controls/validation';
import { Property, SocialLogin, StateAfterAuth } from '@ui/shared/models';
import { UrlUtilService } from 'tenant-pool/core';
import * as fromBaseState from 'libs/infrastructure/base-state';
import * as fromAppState from 'tenant-pool/+state';
import { filter, map } from 'rxjs/operators';
import { MainPageNavigation, storageKeys } from 'tenant-pool/config';
import {
  LocalStorageService,
  SessionStorageService,
  WINDOW_REF
} from 'libs/infrastructure';
import { getRedirectUrl } from 'libs/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ModalContentComponent,
  ModalService,
  ModalV2Component
} from 'libs/components/legacy';
import { SocialLoginConfirmationModalComponent } from 'tenant-pool/components/social-login-confirmation-modal/social-login-confirmation-modal.component';
import { RegisterCardComponent } from 'libs/components/molecules';
import { AsyncPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-portal-register-modal',
  templateUrl: './portal-register-modal.component.html',
  styleUrls: ['./portal-register-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalV2Component,
    ModalContentComponent,
    RegisterCardComponent,
    AsyncPipe
  ],
  standalone: true
})
export class PortalRegisterModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);
  private formBuilder = inject(FormBuilder);
  private urlHelperService = inject(UrlUtilService);
  private store = inject<Store<fromBaseState.BaseState>>(Store);
  private localStorageService = inject(LocalStorageService);
  private sessionStorageService = inject(SessionStorageService);
  private modalService = inject(ModalService);
  private windowRef = inject(WINDOW_REF);

  @Input() property: Property;
  @Input() socialLogin: SocialLogin;

  public form: FormGroup;

  public registerProcessing$: Observable<boolean>;
  public registerError$: Observable<string>;

  public get getLogoRedirectUrl() {
    return this.urlHelperService.getLogoRedirectUrl(
      this.property?.branding?.logoRedirectUrl
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', Validators.required],
      email: [
        '',
        Validators.compose([Validators.required, customEmailValidator])
      ],
      acceptPolicies: [null, Validators.requiredTrue],
      optInProspect: [false]
    });

    this.registerProcessing$ = this.store.select(
      fromAppState.getRegisterProcessing
    );
    this.registerError$ = this.store
      .select(fromAppState.getRegisterError)
      .pipe(map(err => err && err.message));

    this.store
      .select(fromAppState.getRegisterDone)
      .pipe(
        untilDestroyed(this),
        filter(done => done)
      )
      .subscribe(() => this.closeModal());

    const personalInformation = this.sessionStorageService.getItem(
      storageKeys.personalInformation
    );

    if (this.socialLogin?.isSocialLogin) {
      if (this.form) {
        this.form.get('password').disable();
        this.form.get('email').disable();
      }
      this.patchForm(this.socialLogin);
    } else if (personalInformation) {
      this.patchForm(personalInformation);
    }
  }

  public onRegister() {
    const userData = {
      brandedCustomerId: this.property?.customer?.id,
      ...this.form.value
    };

    if (this.socialLogin?.isSocialLogin) {
      userData.email = this.socialLogin.email;
      userData.password = '';
      userData.socialLogin = this.socialLogin.isSocialLogin;
    }

    this.store.dispatch(new fromAppState.Register(userData));

    // clean up (removeItem) might be unecessary
    this.sessionStorageService.removeItem(storageKeys.personalInformation);
    this.localStorageService.removeItem(storageKeys.landlordThemeToken);
    this.localStorageService.setItem(storageKeys.portalRegistration, true);
  }

  public onLoginRedirect() {
    this.store.dispatch(
      new fromBaseState.Go({ path: [MainPageNavigation.LOGIN] })
    );
    this.closeModal();
  }

  public onSocialLogin($event: string) {
    let pathAfterAuth: string;

    if (this.property) {
      pathAfterAuth = `apply/${this.property.id}`;
    } else {
      pathAfterAuth = 'auth/portal-register';
    }

    const stateAfterAuth: StateAfterAuth = {
      pathAfterAuth,
      queryParams: {
        socialLoginType: $event,
        socialLogin: true
      }
    };

    const redirectUri = getRedirectUrl(
      this.windowRef.location.toString(),
      '/auth',
      stateAfterAuth
    );

    const socialLoginConfirmation =
      this.modalService.open<SocialLoginConfirmationModalComponent>(
        SocialLoginConfirmationModalComponent,
        { centered: true }
      );
    socialLoginConfirmation.onClose().subscribe(() => {
      this.store.dispatch(
        new fromBaseState.UserLogin({
          redirectUri,
          loginMethod: $event
        })
      );
    });

    this.localStorageService.removeItem('landlordThemeToken');
  }

  public closeModal() {
    this.ngbActiveModal.close();
  }

  private patchForm(data) {
    if (this.form && this.form.get('firstName').value === '') {
      this.form.get('firstName').patchValue(data.firstName);
    }
    if (this.form && this.form.get('lastName').value === '') {
      this.form.get('lastName').patchValue(data.lastName || data.name);
    }
    if (this.form && this.form.get('email').value === '') {
      this.form.get('email').patchValue(data.email);
    }
  }
}
