import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  BaseState,
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  FormFieldComponent,
  Go,
  ImageComponent,
  LogoComponent,
  StatusInfoComponent,
  StatusInfoImageReplaceConfig,
  ThemePropertyKeys,
  ThemeService,
  UserLogout
} from '@ui/legacy-lib';
import { Attachment } from '@ui/shared/models';
import { map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { getRegisterResidentActionState, RegisterResident } from '../../+state';

@UntilDestroy()
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [
    ReactiveFormsModule,
    TranslateModule,
    StatusInfoComponent,
    LogoComponent,
    ImageComponent,
    CardComponent,
    CheckboxComponent,
    FormFieldComponent,
    ButtonComponent,
    AsyncPipe
  ]
})
export class TermsAndConditionsComponent implements OnInit {
  public iTPLogo$: Observable<Attachment>;
  public loading = false;
  public svgImageConfig: StatusInfoImageReplaceConfig = {
    imageSrc: 'assets/images/illustrations/Sign_In.svg',
    colorHex: '#EBF2FF',
    replaceVar: ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER
  };
  private fb: FormBuilder = inject(FormBuilder);
  public form = this.fb.control(false, [Validators.requiredTrue]);
  private store: Store = inject(Store<BaseState>);
  private themeService: ThemeService = inject(ThemeService);
  private route: ActivatedRoute = inject(ActivatedRoute);

  public ngOnInit(): void {
    this.iTPLogo$ = this.themeService.themeChange.pipe(
      map(theme => theme.iTPLogo)
    );

    this.store
      .select(getRegisterResidentActionState)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state.pending) {
          this.loading = true;
        }
        if (state.done) {
          this.store.dispatch(
            new Go({
              path: [''],
              query: this.route.snapshot.queryParams
            })
          );
        }
      });
  }

  public onSubmit(): void {
    this.store.dispatch(new RegisterResident());
  }

  public onCancel(): void {
    this.store.dispatch(new UserLogout());
  }
}
