import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';
import { StatusInfoImageReplaceConfig } from 'libs/components/molecules/status-info/status-info.model';
import { BaseState, Go, UserLogout } from 'libs/infrastructure/base-state';
import { ThemePropertyKeys } from 'libs/infrastructure/theme/symbols';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { Attachment } from '@ui/shared/models';
import { Observable, map } from 'rxjs';
import {
  RegisterResident,
  getRegisterResidentActionState
} from 'tenant-pool/+state';

@UntilDestroy()
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule
  ]
})
export class TermsAndConditionsComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private store: Store = inject(Store<BaseState>);
  private themeService: ThemeService = inject(ThemeService);
  private route: ActivatedRoute = inject(ActivatedRoute);

  public iTPLogo$: Observable<Attachment>;
  public loading = false;
  public form = this.fb.control(false, [Validators.requiredTrue]);
  public svgImageConfig: StatusInfoImageReplaceConfig = {
    imageSrc: 'assets/images/illustrations/Sign_In.svg',
    colorHex: '#EBF2FF',
    replaceVar: ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER
  };

  public ngOnInit(): void {
    this.iTPLogo$ = this.themeService.themeChange.pipe(
      map(theme => theme.iTPLogo)
    );

    this.store
      .select(getRegisterResidentActionState)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state.pending) {
          this.loading = true;
        }
        if (state.done) {
          this.store.dispatch(
            new Go({
              path: [''],
              query: this.route.snapshot.queryParams
            })
          );
        }
      });
  }

  public onSubmit(): void {
    this.store.dispatch(new RegisterResident());
  }

  public onCancel(): void {
    this.store.dispatch(new UserLogout());
  }
}
