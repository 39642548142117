<app-modal-v2>
  <div class="modal-m" [formGroup]="form">
    <app-modal-content>
      <div class="modal-body">
        <p class="title-m">{{ 'apply_sales_modal.header_l' | translate }}</p>
        <p class="default-s">
          {{ 'apply_sales_modal.description_m' | translate }}
        </p>
        <app-form-field>
          <app-form-field-label>{{
            'general.first_name_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_firstname' | translate"
            formControlName="firstname"
            class="form-control"
          />
        </app-form-field>
        <app-form-field>
          <app-form-field-label>{{
            'general.name_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_name' | translate"
            formControlName="name"
            class="form-control"
          />
        </app-form-field>
        <app-form-field>
          <app-form-field-label>{{
            'general.email_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_email' | translate"
            formControlName="email"
            class="form-control"
          />
          <div class="email__svg">
            @if (form.get('email').pending) {
              <app-loading-spinner></app-loading-spinner>
            }
            @if (
              form.get('email').valid &&
              form.get('email').dirty &&
              form.get('email').value
            ) {
              <img src="/assets/images/icons/icon-check.svg" />
            }
          </div>
        </app-form-field>
        <app-form-field>
          <app-form-field-label>{{
            'general.phone_l' | translate
          }}</app-form-field-label>
          <input
            appInput
            type="text"
            [placeholder]="'forms.type_phone' | translate"
            formControlName="phone"
            class="form-control"
          />
        </app-form-field>
        <app-form-field>
          <app-form-field-label>{{
            'general.further_info_l' | translate
          }}</app-form-field-label>
          <textarea
            appInput
            count="true"
            [maxValue]="700"
            formControlName="furtherInformation"
            class="form-control"
          ></textarea>
        </app-form-field>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer guest-set-password__footer">
        <app-button (clickEvent)="dismiss()" [type]="'light-bordered'">{{
          'general.cancel_a' | translate
        }}</app-button>
        <app-button
          [disabled]="form.invalid || form.pending"
          (clickEvent)="apply()"
          [type]="'primary'"
          >{{ 'sales.property.apply_now_a' | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
