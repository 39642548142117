import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActionState } from '@ui/legacy-lib';
import { Go } from '@ui/legacy-lib';
import * as fromAppState from 'tenant-pool/+state';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ButtonComponent } from '@ui/legacy-lib';

@Component({
  selector: 'app-saga-transfer-data',
  templateUrl: './saga-transfer-data.component.html',
  styleUrls: ['./saga-transfer-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgTemplateOutlet, AsyncPipe, ButtonComponent],
  standalone: true
})
export class SagaTransferDataComponent implements OnInit {
  private store = inject<Store<fromAppState.AppState>>(Store);
  private route = inject(ActivatedRoute);

  public dataTransferActionState: Observable<ActionState>;
  public token: string;
  public isAccepted: boolean;

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token || '';
    if (!this.token) {
      this.store.dispatch(new Go({ path: ['/login'] }));
    }
    this.dataTransferActionState = this.store.select(
      fromAppState.getSagaDataTransferActionState
    );
  }

  public transfer(accept: boolean) {
    this.isAccepted = accept;
    this.store.dispatch(new fromAppState.SagaDataTransfer(accept, this.token));
  }
}
