import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { Go, SessionStorageService } from '@ui/legacy-lib';

import { MainPageNavigation, storageKeys } from 'tenant-pool/config';

@Component({
  selector: 'app-select-viewing-appointment',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SelectViewingAppointmentComponent implements OnInit {
  private store = inject(Store);
  private sessionStorage = inject(SessionStorageService);
  private route = inject(ActivatedRoute);

  ngOnInit() {
    const { applicationId } = this.route.snapshot.queryParams;
    const stateAfterAuth = {
      pathAfterAuth: `/${MainPageNavigation.EXPOSE}/${String(applicationId)}`
    };

    this.sessionStorage.setItem(
      storageKeys.selectViewingAppointment,
      applicationId
    );
    this.sessionStorage.setItem(storageKeys.stateAfterAuth, stateAfterAuth);

    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.LOGIN]
      })
    );
  }
}
