import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import * as fromAppState from 'tenant-pool/+state';
import { MainPageNavigation } from 'tenant-pool/config';
import { Go } from '@ui/legacy-lib';

@Component({
  selector: 'app-confirm-intent',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class ConfirmIntentComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);

  ngOnInit() {
    const applicationId = this.route.snapshot.params.applicationId || '';
    this.store.dispatch(fromAppState.CacheIntent({ applicationId }));
    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.EXPOSE, applicationId],
        query: {
          origin: `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`
        }
      })
    );
  }
}
