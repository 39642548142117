import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConstantsGuard, TranslationsGuard } from '@ui/legacy-lib';

import {
  ApplicationCreationGuard,
  AuthenticatedShellComponent,
  NewHomeGuard,
  projectRegistrationLinkGuard,
  ServiceCenterGuard,
  TenantTranslationsGuard,
  UnauthenticatedShellComponent
} from './core';

import { AuthGuard, ExtendSearchGuard } from './core/guards';

import { ROUTES as LandingRoutes } from './screens/landings/landings.routes';
import { PropertyNotFoundComponent } from './screens/properties/property-not-found/property-not-found.component';
import { PropertyRentedComponent } from './screens/properties/property-rented/property-rented.component';
import { TermsAndConditionsComponent } from './screens/terms-and-conditions/terms-and-conditions.component';
import { NewHomeResolver } from './core/resolver';
import { MainPageNavigation } from './config';
import { EmailVerificationPendingComponent } from './screens/email-verification-pending/email-verification-pending.component';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login'
  },
  {
    path: 'tenant.app',
    redirectTo: '/auth/login'
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: 'project-registration/:token',
    canActivate: [projectRegistrationLinkGuard],
    children: []
  },
  {
    path: '',
    canActivate: [TranslationsGuard],
    children: [
      /**
       * LandingRoutes contain all the routes which are responsible for
       * landing operations, like apply or confirm/deny intent.
       * LandingModule is loaded eagerly.
       */
      ...LandingRoutes,
      {
        path: 'schufa',
        data: {
          title: 'navigation.schufa_page'
        },
        loadChildren: () =>
          import('./screens/schufa/schufa.module').then(
            module => module.SchufaModule
          )
      },
      {
        path: 'payment',
        data: {
          title: 'navigation.payment_page'
        },
        loadChildren: () =>
          import('./screens/payment/payment.module').then(
            module => module.PaymentModule
          )
      },
      {
        path: 'digitalContract',
        data: {
          title: 'navigation.digital_contract_page'
        },
        loadChildren: () =>
          import('./screens/digital-contract/digital-contract.module').then(
            module => module.DigitalContractModule
          )
      },
      {
        path: 'extend-search-until',
        canActivate: [ExtendSearchGuard],
        children: []
      },
      {
        path: 'extendSearchUntil',
        redirectTo: 'extend-search-until'
      },
      {
        path: MainPageNavigation.TERMS_AND_CONDITIONS,
        component: TermsAndConditionsComponent
      },
      {
        path: MainPageNavigation.EMAIL_VERIFICATION_PENDING,
        component: EmailVerificationPendingComponent
      }
    ]
  },
  {
    path: '',
    canActivate: [TranslationsGuard],
    component: UnauthenticatedShellComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.module').then(module => module.AuthModule)
      },
      {
        path: 'static', // eslint-disable-next-line max-len
        loadChildren: () =>
          import('./screens/static-content/static-content.module').then(
            module => module.StaticContentModule
          )
      },
      {
        path: 'register',
        redirectTo: 'auth/register'
      },
      {
        path: 'guest-register',
        redirectTo: 'auth/guest-register'
      },
      {
        path: 'email-verification',
        redirectTo: 'auth/email-verification'
      },
      {
        path: 'confirm-new-email',
        redirectTo: 'auth/confirm-new-email'
      },
      {
        path: 'reset-password',
        redirectTo: 'auth/reset-password'
      },
      {
        path: 'impersonate',
        redirectTo: 'auth/impersonate'
      },
      {
        path: 'unlock',
        redirectTo: 'auth/unlock'
      }
    ]
  },
  // The routes are done via nested children, because the guards
  // need to be executed in order.
  // This is a technical debt issue and will hopefully be fixed via
  // https://immomio.atlassian.net/browse/ART-5391
  {
    path: '',
    component: AuthenticatedShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [
          TenantTranslationsGuard,
          ConstantsGuard,
          ApplicationCreationGuard,
          NewHomeGuard
        ],
        resolve: {
          hasNewHome: NewHomeResolver
        },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./screens/objects/objects.module').then(
                module => module.ObjectsModule
              )
          },
          {
            path: 'messenger',
            data: {
              hideFooter: true,
              hideEmailBanner: true
            },
            loadChildren: () =>
              import('./screens/messenger/messenger.module').then(
                module => module.MessengerModule
              )
          },
          {
            path: 'service',
            canLoad: [ServiceCenterGuard],
            loadChildren: () =>
              import('./screens/service-center/service-center.module').then(
                module => module.ServiceCenterModule
              )
          },
          {
            path: 'profile',
            loadChildren: () =>
              import('./screens/account/account.module').then(
                module => module.AccountModule
              )
          },
          {
            path: 'settings',
            loadChildren: () =>
              import('./screens/settings/settings.module').then(
                module => module.SettingsModule
              )
          },
          {
            path: 'rent-deposit/:id',
            loadChildren: () =>
              import('./screens/rent-deposit/rent-deposit.module').then(
                module => module.RentDepositModule
              )
          },
          {
            path: `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.PROPERTY_NOT_FOUND}`,
            component: PropertyNotFoundComponent
          },
          {
            path: `${MainPageNavigation.PROPERTY_RENTED}/:propertyId`,
            component: PropertyRentedComponent
          },
          {
            path: '**',
            redirectTo: `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      onSameUrlNavigation: 'reload',
      bindToComponentInputs: true,
      urlUpdateStrategy: 'eager'
    })
  ],
  exports: [RouterModule]
})
export class AppRouterModule {}
