<div class="property-not-found ios-padding-top ios-padding-bottom">
  <div class="property-not-found__content">
    <app-image
      class="property-not-found__image"
      [defaultSrc]="error.image"
    ></app-image>
    <div class="property-not-found__header title-xxl">
      {{ error.title | translate }}
    </div>
    <p class="property-not-found__text">{{ error.description | translate }}</p>
    @if (error?.extraText) {
      <p class="property-not-found__text">{{ error.extraText | translate }}</p>
    }
    @if (error.showButton) {
      <app-button
        [type]="'light-bordered'"
        [iconLeft]="'reset'"
        class="property-not-found__button"
        (clickEvent)="onGoBack()"
        >{{ error.linkLabel | translate }}
      </app-button>
    }
  </div>
</div>
