@if (!(loading$ | async)?.pending) {
  <app-cookie-banner
    [cookiePreference]="cookiesPreference"
    (acceptAll)="acceptCookies($event)"
    (customSettings)="customCookieSettings($event)"
  ></app-cookie-banner>
}
@if (!(loading$ | async)?.pending) {
  @if ((applyDone$ | async) === false) {
    <div class="property-apply__header m0">
      <div class="container p0 mb12">
        <div class="d-flex row m0">
          @if (isMobile) {
            <div class="col-12 col-md-6 pl0">
              <app-button [type]="'link'" (clickEvent)="backToApp()">
                <a routerLink="/properties">{{
                  'general.back_to_app_l' | translate
                }}</a>
              </app-button>
            </div>
          }
          <div class="col-12 col-md-6 pr0">
            <span
              class="property-apply__custom-cookie-settings"
              (click)="customCookieSettings(cookiesPreference)"
              >{{ 'general.change_cookie_settings_a' | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="property-apply__expose"
      [class.property-apply__expose--bottom-spacing]="!isFlat"
    >
      <app-property-expose
        [id]="property.id"
        [property]="property"
        (landingApply)="apply()"
        (applyAnonymous)="applyAnonymous($event)"
        (openSalesModal)="openSalesModal()"
      ></app-property-expose>
    </div>
  } @else {
    <div class="application-confirmation">
      <app-application-confirmation
        [header]="'sales_registration.confirmation_header_l'"
        [description]="'sales_registration.confirmation_explanation_l'"
        [showButton]="false"
      >
      </app-application-confirmation>
    </div>
  }
} @else {
  <div class="property-apply__loader">
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
}
