import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ActionStatusString, ResidentMailActionType } from '@ui/shared/models';
import { ActionState, LoadingSpinnerComponent } from '@ui/legacy-lib';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import * as fromResidentState from '../../../../+state/resident';
import { notificationConfig } from '../../../../config';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-deeplink-redirect',
  templateUrl: './deeplink-redirect.component.html',
  styleUrls: ['./deeplink-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, TranslateModule, LoadingSpinnerComponent]
})
export class DeeplinkRedirectComponent implements OnInit {
  public info = signal<
    { success: boolean; message: string; cssClass: string } | undefined
  >(undefined);
  private route = inject(ActivatedRoute);
  private store = inject<Store<fromResidentState.ResidentState>>(Store);
  // TODO should be in another place, component getting bloated. Need service as well.
  private webAppMap: { [key: string]: { dev?: string; prod?: string } } = {
    bergedorfbille: {
      dev: 'bergedorf-bille.dev.resident',
      prod: 'bergedorf-bille.prod.resident'
    },
    hanova: {
      dev: 'hanova.dev.resident-dev'
    },
    wobau: {
      dev: 'wobau.dev.resident'
    }
  };

  ngOnInit() {
    const {
      appClientName,
      token,
      residentVerificationCode,
      customerIdent,
      registerPath,
      appRedirectPath,
      appRedirectAction,
      erpIdentifiers
    } = this.route.snapshot.params as {
      appClientName: string;
      token: string;
      residentVerificationCode: string;
      customerIdent: string;
      registerPath: string;
      appRedirectPath: string;
      appRedirectAction: ResidentMailActionType;
      erpIdentifiers: string;
    };

    let erpIdentifiersEncoded: string;
    if (erpIdentifiers) {
      erpIdentifiersEncoded = encodeURIComponent(erpIdentifiers);
    }

    this.checkArgumentsAndPrintDebugInformation(
      appClientName,
      token,
      customerIdent,
      registerPath,
      appRedirectPath
    );

    /* Legacy code: At first we only had one variable that was set by the BE. Current live apps rely on this path. */
    const legacyDeeplinkPath = registerPath?.replace('*', '/');
    /*
     * New code to allow more flexibility:
     * appRedirectAction is set inside the email template and tells the app which EP should be called
     * as an (re-)action to the email.
     */
    const deeplinkPath = appRedirectPath?.replace('*', '/');

    /* When no verificationCode is used the placeholder from the backend remains and is removed here  */
    const verificationCode =
      residentVerificationCode &&
      residentVerificationCode !== '${residentVerificationCode}'
        ? residentVerificationCode
        : '';

    if (deeplinkPath) {
      const observedActionState = token
        ? this.callApollo(
            appRedirectAction,
            customerIdent,
            token,
            verificationCode
          )
        : null;

      if (observedActionState) {
        this.handleApolloResult(
          observedActionState,
          deeplinkPath,
          appRedirectAction,
          appClientName,
          token,
          verificationCode,
          erpIdentifiersEncoded
        );
      } else {
        const url = this.getRedirectUrl(
          deeplinkPath,
          appRedirectAction,
          undefined,
          appClientName,
          token,
          verificationCode,
          erpIdentifiersEncoded
        );
        /*
         * show success message, even if there is no webapp and no verify call is made, to avoid ongoing
         * loading spinner and hint user to mobile app (e.g. INVITE_PARTNER redirect)
         */
        this.handleInfo(
          appRedirectAction,
          ActionStatusString.SUCCESS,
          appClientName
        );

        if (url) window.location.href = url;
      }
    } else if (legacyDeeplinkPath) {
      /*
       * only the email RS_RESET_PASSWORD uses the legacyRegisterPath. Since Wankendorfer is live and uses this email
       * we will keep this deeplink URL for now as legacy
       */
      const url = this.getRedirectUrl(
        legacyDeeplinkPath,
        undefined,
        undefined,
        appClientName,
        token,
        verificationCode
      );
      // show message to open this link on the phone where the password can be reset.
      this.handleInfo(
        ResidentMailActionType.RESET_PASSWORD,
        ActionStatusString.SUCCESS
      );

      if (url) window.location.href = url;
    }
  }

  private handleApolloResult(
    observedActionState: Observable<ActionState>,
    deeplinkPath: string,
    action: ResidentMailActionType,
    clientName: string,
    token: string,
    verificationCode: string,
    erpIdentifiers: string
  ) {
    observedActionState
      .pipe(
        filter(actionState => !actionState.pending),
        take(1)
      )
      .subscribe(actionState => {
        const status = this.getActionStatusString(actionState);
        this.handleInfo(action, status);

        const url = this.getRedirectUrl(
          deeplinkPath,
          action,
          status,
          clientName,
          token,
          verificationCode,
          erpIdentifiers
        );

        if (url) window.location.href = url;
      });
  }

  private getRedirectUrl(
    deeplinkPath: string,
    action: ResidentMailActionType = undefined,
    status: string = undefined,
    clientName: string,
    token: string,
    verificationCode = '',
    erpIdentifiers?: string
  ) {
    const webDomain = this.getWebDomain(clientName);
    const segments = [deeplinkPath];

    if (action) {
      segments.push(action);
    }
    if (status) {
      segments.push(status);
    }
    if (token) {
      segments.push(token);
    }
    if (verificationCode) {
      segments.push(verificationCode);
    }
    if (erpIdentifiers) {
      segments.push(erpIdentifiers);
    }

    const segmentsJoined = segments.join('/');
    const appDeeplink = `${clientName}://app/${segmentsJoined}`;
    const webAppUrl = webDomain
      ? `https://portal.${webDomain}.immomio.com/${segmentsJoined}`
      : undefined;

    return this.isDesktop() ? webAppUrl : appDeeplink;
  }

  private getActionStatusString(actionState: ActionState): ActionStatusString {
    if (actionState.done) {
      return ActionStatusString.SUCCESS;
    } else if (actionState.error) {
      return ActionStatusString.ERROR;
    }
    return ActionStatusString.NONE;
  }

  private callApollo(
    appRedirectAction: ResidentMailActionType,
    customerIdent: string,
    token: string,
    residentVerificationCode: string
  ): Observable<ActionState> {
    if (appRedirectAction === ResidentMailActionType.VERIFY_EMAIL) {
      this.store.dispatch(
        fromResidentState.verifyEmail({
          token,
          residentVerificationCode,
          customerIdent
        })
      );
      return this.store.select(
        fromResidentState.getResidentVerifyEmailActionState
      );
    } else if (appRedirectAction === ResidentMailActionType.CHANGE_EMAIL) {
      this.store.dispatch(
        fromResidentState.changeEmail({ token, customerIdent })
      );
      return this.store.select(
        fromResidentState.getResidentChangeEmailActionState
      );
    }
  }

  /* eslint-enable */

  private handleInfo(
    action: ResidentMailActionType,
    status: ActionStatusString,
    appClientName?: string
  ) {
    let message: string;
    let cssClass: string =
      status === ActionStatusString.SUCCESS ? 'info_success' : 'info_error';

    switch (action) {
      case ResidentMailActionType.VERIFY_EMAIL: {
        const { success, error } = notificationConfig.resident.verifyEmail;
        message = status === ActionStatusString.SUCCESS ? success : error;
        break;
      }
      case ResidentMailActionType.CHANGE_EMAIL: {
        const { success, error, pending } =
          notificationConfig.resident.changeEmail;
        message =
          status === ActionStatusString.SUCCESS
            ? success
            : status === ActionStatusString.ERROR
              ? error
              : pending;
        break;
      }
      case ResidentMailActionType.RESET_PASSWORD: {
        message = notificationConfig.resident.resetPassword.pending;
        break;
      }
      case ResidentMailActionType.INVITE_PARTNER: {
        const { success, success_abg } =
          notificationConfig.resident.invitePartner;
        message = appClientName === 'abg' ? success_abg : success;
        if (appClientName === 'abg') {
          cssClass = 'info_warning';
        }
        break;
      }
    }

    this.info.set({
      success: status === ActionStatusString.SUCCESS,
      message,
      cssClass
    });
  }

  private isDesktop = () => {
    return !navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
  };

  private getWebDomain(clientName: string): string | undefined {
    const env = environment.production ? 'prod' : 'dev';
    return this.webAppMap[clientName]?.[env];
  }

  /**
   * Simple debug information should the cloud config not be set to easily debug the issue.
   * TODO could be updated to actually show an error message
   * @param appClientName
   * @param token
   * @param customerIdent
   * @param registerPath
   * @param appRedirectPath
   * @private
   */
  private checkArgumentsAndPrintDebugInformation(
    appClientName: string,
    token: string,
    customerIdent: string,
    registerPath: string,
    appRedirectPath: string
  ) {
    if (!appClientName) {
      console.error('app client name is not set.');
    }

    if (!token) {
      console.error('token is not set.');
    }

    if (!customerIdent) {
      console.error('customer identification is not set.');
    }

    if (!registerPath) {
      console.error('registration path is not set.');
    }

    if (!appRedirectPath) {
      console.error('app redirect path is not set.');
    }
  }
}
