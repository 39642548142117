import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { Go, LocalStorageService } from '@ui/legacy-lib';

import { storageKeys } from 'tenant-pool/config';

@Component({
  selector: 'app-ask-self-disclosure',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class AskSelfDisclosureComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private localStorage = inject(LocalStorageService);

  ngOnInit() {
    const applicationId = this.route.snapshot.params.applicationId || null;

    this.localStorage.setItem(
      storageKeys.selfDisclosureApplicationId,
      applicationId
    );

    this.store.dispatch(
      new Go({
        path: ['/properties']
      })
    );
  }
}
