import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  Attachment,
  CookiePreference,
  CustomerBranding,
  DigitalContract,
  DigitalContractProperty,
  SignatureType,
  User
} from '@ui/shared/models';
import { ThemeService } from '@ui/legacy-lib';
import {
  getCookiesPreference,
  InitCookiesPreference,
  LocalStorageService,
  OpenCookieSettingsModal,
  SetCookiesPreference,
  WINDOW_REF
} from '@ui/legacy-lib';
import { DigitalContractService } from '@ui/legacy-lib';

import { storageKeys } from 'tenant-pool/config';
import * as fromAppState from 'tenant-pool/+state';
import { ENVIRONMENT_CONFIG } from 'tenant-pool/core';
import {
  AttachmentItemComponent,
  AttachmentsListComponent,
  CheckboxComponent,
  CookieBannerComponent,
  FormFieldComponent,
  LoadingSpinnerComponent
} from '@ui/legacy-lib';
import { AsyncPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { CustomerInfoLegacyComponent } from 'tenant-pool/components/customer-info-legacy/customer-info-legacy.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { AddressPipe } from '@ui/legacy-lib';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-digital-contract-landing',
  templateUrl: './digital-contract-landing.component.html',
  styleUrls: ['./digital-contract-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CookieBannerComponent,
    AsyncPipe,
    RouterLink,
    TranslateModule,
    CustomerInfoLegacyComponent,
    AttachmentsListComponent,
    AttachmentItemComponent,
    SvgIconComponent,
    CurrencyPipe,
    AddressPipe,
    DecimalPipe,
    CheckboxComponent,
    FormFieldComponent,
    FormsModule,
    LoadingSpinnerComponent,
    ButtonComponent
  ],
  standalone: true
})
export class DigitalContractLandingComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private localStorageService = inject(LocalStorageService);
  private dmvService = inject(DigitalContractService);
  private translate = inject(TranslateService);
  private themeService = inject(ThemeService);
  private windowRef = inject(WINDOW_REF);
  private environment = inject(ENVIRONMENT_CONFIG);

  public loading$: Observable<boolean>;
  public processing$: Observable<boolean>;
  public cookiesPreference: CookiePreference;

  public contract: DigitalContract;
  public actionRequired: boolean;
  public isTermsAccepted: boolean;
  public token: string;
  public property: DigitalContractProperty;
  public user: User;
  public customerBranding: CustomerBranding;

  public get isActionRequired() {
    return this.actionRequired && !this.contract?.signedDocumentCombinedFile;
  }

  public get signatureRequired() {
    return !this.actionRequired && !this.contract?.signedDocumentCombinedFile;
  }

  public get downloadReady() {
    return this.contract?.signedDocumentCombinedFile;
  }

  public get showShopCard() {
    return this.contract?.branding?.itpSettings?.shopCard;
  }

  public get isEsOrQesDownload() {
    return (
      (this.contract?.signatureType === SignatureType.ES_MAIL ||
        this.contract?.signatureType === SignatureType.QES) &&
      this.contract?.signedDocumentCombinedFile
    );
  }

  public get isAesDownload() {
    return (
      (this.contract?.signatureType === SignatureType.AES_MAIL ||
        this.contract?.signatureType === SignatureType.AES_OFFICE ||
        this.contract?.signatureType === SignatureType.AES_SMS) &&
      this.contract?.signedDocumentSingleFiles
    );
  }

  public get image() {
    return this.property?.titleImage;
  }

  public get customerLogo() {
    return this.customerBranding?.logo?.url || this.property?.customerLogo;
  }

  public get getTotalRent() {
    return (
      this.property.rent +
        this.property.heatingCost +
        Number(this.property.additionalCosts) || 0
    );
  }

  public get isMobile() {
    return this.environment?.mobile;
  }

  public ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';
    this.store.dispatch(new fromAppState.LoadDigitalContract(this.token));
    this.loading$ = this.store.select(fromAppState.getDigitalContractLoading);

    this.processing$ = combineLatest([
      this.store.select(fromAppState.getProcessApplicationActionState),
      this.store.select(fromAppState.getUserDataLoading)
    ]).pipe(map(([processing, loading]) => processing.pending || loading));

    this.store
      .select(getCookiesPreference)
      .pipe(
        filter(cookiesPreference => !!cookiesPreference),
        untilDestroyed(this)
      )
      .subscribe(
        cookiesPreference => (this.cookiesPreference = cookiesPreference)
      );

    this.store
      .select(fromAppState.getDigitalContract)
      .pipe(
        filter(contract => !!contract),
        untilDestroyed(this)
      )
      .subscribe((contract: DigitalContract) => {
        if (contract.branding) {
          this.customerBranding = contract.branding;
          if (contract.branding.theme) {
            this.themeService.createTheme(this.customerBranding);
          }
        }
        this.property = contract.property;
        this.contract = {
          ...contract,
          token: this.token
        };

        this.user = this.dmvService.getUser(this.contract.tenantSigners[0]);
        // initially load the preference from LS and enable tracking if configured
        this.store.dispatch(new InitCookiesPreference(this.user));
        this.actionRequired = contract.actionRequired;
      });
  }

  public backToApp() {
    this.themeService.setTheme(this.themeService.getDefaultTheme);
  }

  public downloadContract(attachment: Attachment) {
    this.store.dispatch(
      new fromAppState.DownloadContract(this.token, attachment.identifier)
    );
  }

  public downloadSingleFileContract(attachment: Attachment) {
    this.store.dispatch(
      new fromAppState.DownloadContract(this.token, attachment.identifier)
    );
  }

  public downloadArchiveContract(attachment: Attachment) {
    this.store.dispatch(
      new fromAppState.DownloadContract(this.token, attachment.identifier)
    );
  }

  public sign() {
    this.localStorageService.setItem(
      storageKeys.digitalContract,
      this.contract
    );
    this.store.dispatch(new fromAppState.Sign());
  }

  public switchLang() {
    const current = this.translate.currentLang;
    const newLocale = current === 'en' ? 'de' : 'en';
    window.location.href = window.location.href.replace(
      `/${current}/`,
      `/${newLocale}/`
    );
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new SetCookiesPreference(payload, this.user));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(new OpenCookieSettingsModal(payload, true, this.user));
  }
}
