import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  Appointment,
  AppointmentAcceptanceCancelReason,
  AppointmentAcceptanceShortInfo,
  AppointmentInvitationState,
  AppointmentPropertyShortInfo,
  AppointmentsBundles,
  AppointmentSelection,
  NameValue
} from '@ui/shared/models';
import { ModalService } from '@ui/legacy-lib';
import { AppointmentsModalComponent } from '@ui/legacy-lib';

import { ConfirmReasonModalComponent } from '@ui/legacy-lib';
import { sharedDialogConfig } from '@ui/legacy-lib';
import * as fromAppState from 'tenant-pool/+state';
import { AppointmentsPropertyItemComponent } from 'tenant-pool/components/appointments-property-item/appointments-property-item.component';
import { AsyncPipe } from '@angular/common';
import { NoContentComponent } from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { getConstants } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-guest-viewing',
  templateUrl: './guest-viewing.component.html',
  styleUrls: ['./guest-viewing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AppointmentsPropertyItemComponent,
    AsyncPipe,
    NoContentComponent,
    TranslateModule
  ],
  standalone: true
})
export class GuestViewingComponent implements OnInit {
  private store = inject<Store<fromAppState.AppState>>(Store);
  private route = inject(ActivatedRoute);
  private modalService = inject(ModalService);

  public appointmentsBundleData$: Observable<AppointmentsBundles[]>;
  public guestAppointmentsLoading$: Observable<boolean>;
  public token: string;
  public appointmentAcceptanceCancelReasonTypes: NameValue[];

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';

    this.store
      .select(getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(
        constants =>
          (this.appointmentAcceptanceCancelReasonTypes =
            constants.appointmentAcceptanceCancelReasonTypes)
      );

    this.store.dispatch(new fromAppState.LoadGuestAppointments(this.token));

    this.appointmentsBundleData$ = this.store.select(
      fromAppState.getGuestAppointmentsBundles
    );
    this.guestAppointmentsLoading$ = this.store.select(
      fromAppState.getGuestAppointmentsLoading
    );
  }

  public onExportToCalendar(application: {
    property: AppointmentPropertyShortInfo;
    appointment: Appointment;
  }) {
    this.store.dispatch(
      new fromAppState.ExportCalendarFile(application, this.token)
    );
  }

  public filterAppointments(
    appointments: Appointment[],
    appointmentAcceptances: AppointmentAcceptanceShortInfo[]
  ) {
    return appointments?.filter(
      appointment =>
        (appointment.state === AppointmentInvitationState.ACTIVE &&
          !appointment.full) ||
        appointmentAcceptances.some(
          appointmentAcceptance =>
            appointmentAcceptance.appointmentId === appointment.id
        )
    );
  }

  public onSelectAppointment(appointmentsBundles: AppointmentsBundles) {
    let appointments = appointmentsBundles.appointments.filter(
      appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
    );

    appointments = appointments
      .filter(
        appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
      )
      .filter(
        appointment =>
          !appointmentsBundles.appointmentInvitations.some(invitation => {
            return invitation.appointmentId === appointment.id;
          })
      );
    const exclusiveAppointments = appointmentsBundles.appointments
      .filter(
        appointment => appointment.maxInviteeCount > appointment.sizeOfAttendees
      )
      .filter(appointment =>
        appointmentsBundles.appointmentInvitations.some(invitation => {
          return invitation.appointmentId === appointment.id;
        })
      );

    const data = {
      ...appointmentsBundles,
      appointments,
      exclusiveAppointments
    };
    this.modalService
      .open<AppointmentsModalComponent>(AppointmentsModalComponent, { data })
      .onClose()
      .subscribe((result: AppointmentSelection) => {
        if (result.appointmentAcceptanceId === 'non_fitting') {
          return this.store.dispatch(
            new fromAppState.NoAppointmentFitting({
              applicationId: result.applicationId,
              reasonType:
                AppointmentAcceptanceCancelReason.APPOINTMENT_DOES_NOT_FIT
            })
          );
        }
        const action = new fromAppState.AcceptGuestAppointment(
          result.appointment.id.toString(),
          this.token
        );
        return this.store.dispatch(action);
      });
  }

  public onCancelAppointment(appointmentId: string) {
    this.modalService
      .open<ConfirmReasonModalComponent>(ConfirmReasonModalComponent, {
        data: {
          ...sharedDialogConfig.appointment.cancelAppointment,
          reasons: this.appointmentAcceptanceCancelReasonTypes
        }
      })
      .onClose()
      .subscribe(data =>
        this.store.dispatch(
          new fromAppState.CancelGuestAppointment(
            {
              ...data,
              appointmentId: appointmentId
            },
            this.token
          )
        )
      );
  }
}
