import {
  Component,
  inject,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ModalService } from 'libs/components/legacy/modal';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ToastService } from 'libs/components/legacy/toast';
import {
  I18nService,
  ScrollerDirective,
  ScrollerService
} from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { UserConversionService } from 'libs/services';

import * as fromUserSelector from 'tenant-pool/+state/user/user.selectors';
import { ENVIRONMENT_CONFIG } from 'tenant-pool/core';
import { NewRelicService } from 'libs/infrastructure/data-privacy/new-relic.service';
import { ConversationSenderTypes } from 'libs/components/legacy/messenger/model/enum';
import { NewUpdateBannerComponent } from 'libs/components/legacy/new-update-banner/new-update-banner.component';
import { LoadingSpinnerComponent } from 'libs/components/legacy';
import { RouterOutlet } from '@angular/router';
import { ThemeComponent } from 'libs/infrastructure/theme/components/theme/theme.component';
import { ThemeDirective } from 'libs/infrastructure/theme/theme.directive';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    LoadingSpinnerComponent,
    RouterOutlet,
    ThemeComponent,
    ThemeDirective,
    ScrollerDirective,
    AsyncPipe
  ],
  standalone: true
})
export class AppComponent implements OnInit {
  private store = inject<Store<fromBaseState.BaseState>>(Store);
  private i18nService = inject(I18nService);
  private viewContainerRef = inject(ViewContainerRef);
  private toastService = inject(ToastService);
  private scrollerService = inject(ScrollerService);
  private themeService = inject(ThemeService);
  private userConversionService = inject(UserConversionService);
  private environment = inject(ENVIRONMENT_CONFIG);
  private newRelicService = inject(NewRelicService);
  private swUpdate = inject(SwUpdate);
  private modalService = inject(ModalService);

  public finishedLoaded$: Observable<boolean>;

  constructor() {
    this.toastService.setRootViewContainerRef(this.viewContainerRef);
    this.i18nService.loadDefaultTranslations();
    this.scrollerService.enableAllRoutes();
    this.newRelicService.init();

    this.finishedLoaded$ = this.store.select(
      fromBaseState.getTranslationLoaded
    );
    this.store.dispatch(
      new fromBaseState.SetSender(ConversationSenderTypes.PROPERTYSEARCHER)
    );
    this.themeService.init(this.environment);
  }

  public ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          )
        )
        .subscribe(() =>
          this.modalService
            .open<NewUpdateBannerComponent>(NewUpdateBannerComponent, {
              backdrop: 'static'
            })
            .onClose()
            .subscribe(() =>
              this.store.dispatch(new fromBaseState.UpdateApplicationSuccess())
            )
        );
    }
    this.store
      .select(fromUserSelector.getUserData)
      .pipe(
        filter(userData => !!userData && !!userData.id),
        map(propertySearcher =>
          this.userConversionService.convertPsUser(propertySearcher)
        ),
        take(1)
      )
      .subscribe(userData => {
        // initially load the preference from LS and enable tracking if configured
        this.store.dispatch(new fromBaseState.InitCookiesPreference(userData));
      });
  }
}
