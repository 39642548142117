<div
  class="wrapper d-flex p-3 justify-content-center text-center max-h-full align-items-sm-center"
>
  <div class="content d-flex flex-column w-100">
    <div class="align-self-center flex-grow-1 flex-sm-grow-0 d-flex">
      <p class="mb-0 mb-sm-5 mt-5 title-m text-center">
        {{ 'email_verification_pending.title_l' | translate }}
      </p>
    </div>

    <div class="d-flex align-items-center justify-content-center flex-column">
      <img
        class="mb-4"
        [src]="'/assets/images/email-verification.svg'"
        alt=""
      />
      <p class="title-xl">
        {{ 'email_verification_pending.confirm_title_l' | translate }}
      </p>
      <p
        class="mb-5"
        [innerHTML]="'email_verification_pending.confirm_m' | translate"
      ></p>
    </div>
    <div class="flex-grow-1 flex-sm-grow-0 d-flex align-items-end">
      <!-- If someone just registered, then he has no token and can't request the email again -->
      <!-- So this button will be displayed if the user logs in again -->
      @if (!justRegistered()) {
        <div
          class="mb-5 mb-sm-0 w-100 d-flex justify-content-start flex-column"
        >
          <p class="mb-2 mt-sm-4 text-start d-inline-block title-xs semibold">
            {{ 'email_verification_pending.resend_email_hint_m' | translate }}
          </p>
          <app-button
            [type]="ButtonTypeEnum.LIGHT_BORDERED"
            [disabled]="buttonClicked()"
            (clickEvent)="resendVerificationEmail()"
          >
            {{ 'email_verification_pending.resend_email_button_l' | translate }}
          </app-button>
        </div>
      }
    </div>
  </div>
</div>
