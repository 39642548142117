<div class="terms-and-conditions__background">
  <div class="terms-and-conditions__wrapper">
    <app-status-info
      class="terms-and-conditions__status-info"
      [whitelabelSvg]="svgImageConfig"
      [imageSize]="'60%'"
    >
      <div class="mb-4 d-flex justify-content-between" status-info-header>
        <app-logo></app-logo>
        <div class="terms-and-conditions__in-cooperation d-flex flex-column">
          @if (iTPLogo$ | async) {
            <span class="tiny">{{
              'general.in_cooperation_with' | translate
            }}</span>
            <app-image
              defaultSrc="/assets/images/logos/logo-immomio-main.svg"
              alt="{{ 'general.immomio_logo_alt' | translate }}"
            ></app-image>
          }
        </div>
      </div>
      <div class="mb-5 mx-2" status-info-header>
        <div class="title-xxl text-start">
          {{ 'terms_and_conditions.welcome_1' | translate }}
        </div>
        <div class="title-m text-start">
          {{ 'terms_and_conditions.welcome_2' | translate }}
        </div>
      </div>
      <p class="mt-5 mb-4 text-start" status-info-description>
        {{ 'terms_and_conditions.please_accept' | translate }}
      </p>
      <form class="terms-and-conditions__form" status-info-button>
        <app-card class="pb-4">
          <app-form-field [disableErrorMessages]="true">
            <app-checkbox
              class="text-start"
              [innerHTML]="'terms_and_conditions.understand' | translate"
              [formControl]="form"
              required
            ></app-checkbox>
          </app-form-field>
        </app-card>
        <div class="d-flex flex-column gap-2">
          <app-button
            [disabled]="form.invalid"
            [loading]="loading"
            (clickEvent)="onSubmit()"
            >{{ 'general.login_a' | translate }}</app-button
          >
          <app-button type="light-bordered" (clickEvent)="onCancel()">{{
            'general.cancel_a' | translate
          }}</app-button>
        </div>
      </form>
    </app-status-info>
  </div>
</div>
