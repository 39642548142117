import {
  Component,
  inject,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import {
  ConversationSenderTypes,
  getTranslationLoaded,
  I18nService,
  InitCookiesPreference,
  LoadingSpinnerComponent,
  ModalService,
  NewRelicService,
  NewUpdateBannerComponent,
  ScrollerDirective,
  ScrollerService,
  SetSender,
  ThemeComponent,
  ThemeDirective,
  ThemeService,
  ToastService,
  UpdateApplicationSuccess,
  UserConversionService
} from '@ui/legacy-lib';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import * as fromUserSelector from 'tenant-pool/+state/user/user.selectors';
import { ENVIRONMENT_CONFIG } from 'tenant-pool/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    LoadingSpinnerComponent,
    RouterOutlet,
    ThemeComponent,
    ThemeDirective,
    ScrollerDirective,
    AsyncPipe
  ],
  standalone: true
})
export class AppComponent implements OnInit {
  private store = inject(Store);
  private i18nService = inject(I18nService);
  private viewContainerRef = inject(ViewContainerRef);
  private toastService = inject(ToastService);
  private scrollerService = inject(ScrollerService);
  private themeService = inject(ThemeService);
  private userConversionService = inject(UserConversionService);
  private environment = inject(ENVIRONMENT_CONFIG);
  private newRelicService = inject(NewRelicService);
  private swUpdate = inject(SwUpdate);
  private modalService = inject(ModalService);

  public finishedLoaded$: Observable<boolean>;

  constructor() {
    this.toastService.setRootViewContainerRef(this.viewContainerRef);
    this.i18nService.loadDefaultTranslations();
    this.scrollerService.enableAllRoutes();
    this.newRelicService.init();

    this.finishedLoaded$ = this.store.select(getTranslationLoaded);
    this.store.dispatch(
      new SetSender(ConversationSenderTypes.PROPERTYSEARCHER)
    );
    this.themeService.init(this.environment);
  }

  public ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          )
        )
        .subscribe(() =>
          this.modalService
            .open<NewUpdateBannerComponent>(NewUpdateBannerComponent, {
              backdrop: 'static'
            })
            .onClose()
            .subscribe(() =>
              this.store.dispatch(new UpdateApplicationSuccess())
            )
        );
    }
    this.store
      .select(fromUserSelector.getUserData)
      .pipe(
        filter(userData => !!userData && !!userData.id),
        map(propertySearcher =>
          this.userConversionService.convertPsUser(propertySearcher)
        ),
        take(1)
      )
      .subscribe(userData => {
        // initially load the preference from LS and enable tracking if configured
        this.store.dispatch(new InitCookiesPreference(userData));
      });
  }
}
