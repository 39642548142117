@if (!(loading$ | async)?.pending) {
  <app-cookie-banner
    [cookiePreference]="cookiesPreference"
    (acceptAll)="acceptCookies($event)"
    (customSettings)="customCookieSettings($event)"
  ></app-cookie-banner>
}

<div class="direct-apply-page">
  <div class="container container--with-spacing">
    @if (!(loading$ | async)?.pending && !existingSession) {
      <app-register-card
        [form]="form"
        [property]="property"
        [disabled]="registerProcessing$ | async"
        [errorMessage]="registerError$ | async"
        [socialLogin]="socialLogin"
        [guestLoginRedirectUrl]="'/guest-register'"
        [showLoginAsGuest]="property?.allowContinueAsGuest"
        (registerEvent)="onRegister()"
        (loginRedirectEvent)="onLoginRedirect()"
        (socialLoginEvent)="onSocialLogin($event)"
      ></app-register-card>
    } @else {
      <div class="loader">
        <div class="loading-area">
          <app-loading-spinner></app-loading-spinner>
        </div>
      </div>
    }
  </div>
</div>
