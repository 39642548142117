import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

import { AuthTokenService, LocalStorageService } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { CookiePreference, Property, SocialLogin } from '@ui/shared/models';
import { ActionState } from 'libs/state-utils';

import * as fromAppState from 'tenant-pool/+state';
import { MainPageNavigation, storageKeys } from 'tenant-pool/config';
import {
  AsyncValidatorsService,
  ENVIRONMENT_CONFIG,
  UrlUtilService
} from 'tenant-pool/core';
import {
  CookieBannerComponent,
  LoadingSpinnerComponent,
  ModalService
} from 'libs/components/legacy';
import { ApplySalesModalComponent } from 'tenant-pool/screens/landings/components/property-apply/components/apply-sales-modal/apply-sales-modal.component';
import { isPropertyTypeFlat, isSalesObject } from 'libs/utils';
import { PortalRegisterModalComponent } from 'tenant-pool/auth/portal-register-modal/portal-register-modal.component';
import { AsyncPipe } from '@angular/common';
import { PropertyExposeComponent } from 'tenant-pool/components/property-expose/property-expose.component';
import {
  ApplicationConfirmationComponent,
  ButtonComponent
} from 'libs/components/atoms';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-property-apply',
  templateUrl: './property-apply.component.html',
  styleUrls: ['./property-apply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CookieBannerComponent,
    AsyncPipe,
    RouterLink,
    PropertyExposeComponent,
    ApplicationConfirmationComponent,
    LoadingSpinnerComponent,
    ButtonComponent,
    TranslateModule
  ],
  standalone: true
})
export class PropertyApplyComponent implements OnInit {
  private store = inject<Store<fromBaseState.BaseState>>(Store);
  private route = inject(ActivatedRoute);
  private themeService = inject(ThemeService);
  private urlHelperService = inject(UrlUtilService);
  private localStorageService = inject(LocalStorageService);
  private fb = inject(FormBuilder);
  private modalService = inject(ModalService);
  private authTokenService = inject(AuthTokenService);
  private asyncValidatorsService = inject(AsyncValidatorsService);
  private environment = inject(ENVIRONMENT_CONFIG);

  public loading$: Observable<ActionState>;
  public processing$: Observable<boolean>;
  public cookiesPreference: CookiePreference;

  public property: Property;

  public applyForm: FormGroup;
  public applyDone$: Observable<boolean>;
  public socialLogin: SocialLogin;

  public get getLogoRedirectUrl() {
    return this.urlHelperService.getLogoRedirectUrl(
      this.property?.branding?.logoRedirectUrl
    );
  }

  public get image() {
    return this.property?.titleImage;
  }

  public get isMobile() {
    return this.environment?.mobile;
  }

  public get isFlat() {
    return isPropertyTypeFlat(this.property?.type);
  }

  public get isSales(): boolean {
    return isSalesObject(this.property?.marketingType);
  }

  public get shortUrlToken() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.route?.snapshot?.queryParams?.token;
  }

  public ngOnInit() {
    if (this.route?.snapshot?.queryParams?.identifyToken) {
      this.localStorageService.setItem(
        storageKeys.identityToken,
        this.route?.snapshot?.queryParams?.identifyToken
      );
    }

    this.store.dispatch(
      new fromAppState.LoadProperty(this.route.snapshot.params.id)
    );

    this.loading$ = this.store.select(fromAppState.getLoadPropertyActionState);
    this.applyDone$ = this.store.select(fromAppState.guestApplyDone);

    this.processing$ = combineLatest([
      this.store.select(fromAppState.getProcessApplicationActionState),
      this.store.select(fromAppState.getProcessAnonymousApplicationActionState),
      this.store.select(fromAppState.getUserDataLoading)
    ]).pipe(
      map(
        ([processing, processingAnon, loading]) =>
          processing.pending || processingAnon.pending || loading
      )
    );

    this.store
      .select(fromAppState.getProperty)
      .pipe(
        filter(property => !!property),
        untilDestroyed(this)
      )
      .subscribe(property => {
        if (property?.branding?.theme?.name) {
          this.themeService.createTheme(property.branding);
        }
        this.property = property;

        // If property is delegated but ps used old apply link of property before it was delegated, we redirect with new id
        if (property.id !== this.route.snapshot.params.id) {
          this.store.dispatch(
            new fromBaseState.Go({ path: [`apply/${property.id}`] })
          );
        }

        this.initFormGroup();
      });

    // initially load the preference from LS and enable tracking if configured
    this.store.dispatch(new fromBaseState.InitCookiesPreference());

    this.store
      .select(fromBaseState.getCookiesPreference)
      .pipe(
        filter(cookiesPreference => !!cookiesPreference),
        untilDestroyed(this)
      )
      .subscribe(
        cookiesPreference => (this.cookiesPreference = cookiesPreference)
      );

    this.store
      .select(fromAppState.getSetStorageForApplyActionState)
      .pipe(untilDestroyed(this))
      .subscribe(setStorageForApplyActionState => {
        if (setStorageForApplyActionState.done) {
          if (!this.authTokenService.getToken().access_token) {
            this.openApplyModal();
          } else {
            this.store.dispatch(
              new fromBaseState.Go({
                path: [
                  MainPageNavigation.PROPERTIES,
                  MainPageNavigation.APPLICATIONS
                ]
              })
            );
          }
        }
      });

    this.socialLogin = {
      isSocialLogin:
        this.route.snapshot.queryParams.registerAfterSocialLogin === 'true',
      email: this.route.snapshot.queryParams.socialLoginEmail,
      firstName: this.route.snapshot.queryParams.firstName,
      lastName: this.route.snapshot.queryParams.lastName,
      type: this.route.snapshot.queryParams.type
    };

    if (this.socialLogin?.isSocialLogin) {
      this.openApplyModal();
    }
  }

  private initFormGroup() {
    const email = this.route.snapshot.queryParams.email;
    const firstname = this.route.snapshot.queryParams.firstname;
    const name = this.route.snapshot.queryParams.name;

    this.applyForm = this.fb.group({
      email: [
        '',
        {
          validators: Validators.compose([
            Validators.required,
            Validators.email
          ]),
          asyncValidators: this.asyncValidatorsService.guestEmailValidator(
            this.property.id
          )
        }
      ],
      firstname: ['', Validators.required],
      furtherInformation: '',
      name: ['', Validators.required],
      phone: [this.route.snapshot.queryParams.phone || '']
    });

    // All fields need to be validated when setting them initially, so they have been validated at least once

    if (email) {
      this.applyForm.controls['email'].setValue(email);
      // The email needs to be marked as dirty to trigger the async validation
      this.applyForm.controls['email'].markAsDirty();
      // Mark the field as touched to show an error as happened
      this.applyForm.controls['email'].markAsTouched();
    }

    if (firstname) {
      this.applyForm.controls['firstname'].setValue(firstname);
    }

    if (name) {
      this.applyForm.controls['name'].setValue(name);
    }
  }

  public backToApp() {
    this.themeService.setTheme(this.themeService.getDefaultTheme);
  }

  public apply() {
    this.localStorageService.setItem(
      storageKeys.customQuestionApplicationId,
      this.property.id
    );

    this.store.dispatch(new fromAppState.SetStorageForApply());
  }

  public applyAnonymous(anonymousApplyForm) {
    const payload = {
      ...anonymousApplyForm,
      propertyId: this.property.id,
      shortUrlToken: this.shortUrlToken
    };
    this.store.dispatch(
      fromAppState.ApplyAnonymously({ anonymousApplicationPayload: payload })
    );
  }

  public openSalesModal() {
    const salesLegalTextIds = this.property.salesLegalTexts?.map(
      text => text.id
    );
    this.modalService
      .open<ApplySalesModalComponent>(ApplySalesModalComponent, {
        data: {
          propertyId: this.property.id,
          form: this.applyForm
        }
      })
      .onClose()
      .pipe(
        withLatestFrom(this.store.select(fromAppState.getGuestRegisterStatus)),
        take(1)
      )
      .subscribe(([payload, { token }]) =>
        this.store.dispatch(
          new fromAppState.ApplyAsGuest(
            { ...payload, salesLegalTextIds },
            token
          )
        )
      );
  }

  public openApplyModal() {
    this.modalService.open<PortalRegisterModalComponent>(
      PortalRegisterModalComponent,
      {
        data: { property: this.property, socialLogin: this.socialLogin },
        centered: true
      }
    );
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new fromBaseState.SetCookiesPreference(payload));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(
      new fromBaseState.OpenCookieSettingsModal(payload, true)
    );
  }
}
