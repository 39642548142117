<div class="dl-redirect-container">
  @let information = info();
  @defer (when information) {
    <div
      [ngClass]="{
        info_success: information.success,
        info_error: !information.success
      }"
      class="info"
    >
      {{ information.message | translate }}
    </div>
  } @placeholder (minimum 2000ms) {
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
      <div class="mt-2">{{ 'deeplink.verifying_user_l' | translate }}</div>
    </div>
  }
</div>
