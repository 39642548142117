import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class ThemeComponent {}
