import { Routes } from '@angular/router';

import {
  PropertyApplyComponent,
  ConfirmIntentComponent,
  DenyIntentComponent,
  AskSelfDisclosureComponent,
  DigitalContractLandingComponent,
  VerifyCodeLandingComponent,
  SelectViewingAppointmentComponent,
  ProposalOfferedComponent,
  SagaTransferDataComponent,
  DeeplinkRedirectComponent,
  DirectApplyComponent
} from './components';
import { GuestModeLandingComponent } from './components/guest-mode/guest-mode-landing.component';
import { GuestViewingComponent } from './components/guest-mode/components/guest-viewing/guest-viewing.component';
import { GuestIntentComponent } from './components/guest-mode/components/guest-intent/guest-intent.component';

export const ROUTES: Routes = [
  {
    path: 'apply/:id',
    component: PropertyApplyComponent,
    data: {
      title: 'navigation.apply',
      isApplyPage: true
    }
  },
  {
    path: 'apply/:id/direct',
    component: DirectApplyComponent
  },
  {
    path: 'confirm-intent/:applicationId',
    component: ConfirmIntentComponent
  },
  {
    path: 'deny-intent',
    component: DenyIntentComponent
  },
  // current emails: RS_RESET_PASSWORD
  {
    path: 'deeplink-redirect/:appClientName/:registerPath/:token',
    component: DeeplinkRedirectComponent
  },
  {
    path: 'deeplink-redirect/:appClientName/:appRedirectPath/:appRedirectAction/:customerIdent/:token',
    component: DeeplinkRedirectComponent
  },
  {
    path: 'deeplink-redirect/:appClientName/:appRedirectPath/:appRedirectAction/:erpIdentifiers',
    component: DeeplinkRedirectComponent
  },
  {
    path: 'deeplink-redirect/:appClientName/:appRedirectPath/:appRedirectAction/:customerIdent/:token/:residentVerificationCode',
    component: DeeplinkRedirectComponent
  },
  {
    path: 'ask-self-disclosure/:applicationId',
    component: AskSelfDisclosureComponent
  },
  {
    path: 'select-viewing-appointment',
    component: SelectViewingAppointmentComponent
  },
  {
    path: 'proposal-offered',
    component: ProposalOfferedComponent
  },
  {
    path: 'signContract',
    component: DigitalContractLandingComponent
  },
  {
    path: 'verifyCode',
    component: VerifyCodeLandingComponent
  },
  {
    path: 'guest',
    component: GuestModeLandingComponent,
    children: [
      {
        path: 'viewing',
        component: GuestViewingComponent
      },
      {
        path: 'intent',
        component: GuestIntentComponent
      }
    ]
  },
  {
    path: 'saga-data-transfer',
    component: SagaTransferDataComponent
  },
  /**
   * Safe route redirects.
   * Those are fallbacks after changing our routes structure and
   * app's architecture.
   * Most of the outside links points to the routes specified above,
   * but we need to make sure nothing is broken.
   */
  {
    path: 'properties/apply/:id',
    redirectTo: 'apply/:id'
  },
  {
    path: 'properties/confirm-intent/:applicationId',
    redirectTo: 'confirm-intent/:applicationId'
  },
  {
    path: 'properties/deny-intent',
    redirectTo: 'deny-intent'
  },
  {
    path: 'properties/ask-self-disclosure/:applicationId',
    redirectTo: 'ask-self-disclosure/:applicationId'
  }
];
