import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { CookiePreference, NameValue } from '@ui/shared/models';

import * as fromAppState from 'tenant-pool/+state';
import {
  ConfirmReasonComponent,
  CookieBannerComponent,
  LoadingSpinnerComponent
} from 'libs/components/legacy';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from 'libs/components/atoms';

@UntilDestroy()
@Component({
  selector: 'app-deny-intent',
  templateUrl: './deny-intent.component.html',
  styleUrls: ['./deny-intent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CookieBannerComponent,
    AsyncPipe,
    ConfirmReasonComponent,
    TranslateModule,
    LoadingSpinnerComponent,
    ButtonComponent
  ],
  standalone: true
})
export class DenyIntentComponent implements OnInit {
  private store = inject<Store<fromBaseState.BaseState>>(Store);
  private route = inject(ActivatedRoute);

  public loading$: Observable<boolean>;
  public cookiesPreference: CookiePreference;
  public token: string;
  public reasonTypeModel: string;
  public otherReasonText: string;
  public reasons: NameValue[];

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';
    this.store.dispatch(new fromBaseState.FetchConstants());

    this.loading$ = this.store.select(fromBaseState.getConstantsLoading);

    this.store
      .select(fromBaseState.getCookiesPreference)
      .pipe(
        filter(cookiesPreference => !!cookiesPreference),
        untilDestroyed(this)
      )
      .subscribe(
        cookiesPreference => (this.cookiesPreference = cookiesPreference)
      );

    this.store
      .select(fromBaseState.getConstants)
      .pipe(
        filter(constants => !!constants),
        untilDestroyed(this)
      )
      .subscribe(constants => (this.reasons = constants.refusalReasonTypes));
  }

  public onDenyIntent() {
    const payload = {
      intent: false,
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    };
    this.store.dispatch(
      fromAppState.DenyIntent({ declareIntentData: payload, token: this.token })
    );
    this.store.dispatch(new fromBaseState.Go({ path: ['/login'] }));
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new fromBaseState.SetCookiesPreference(payload));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(
      new fromBaseState.OpenCookieSettingsModal(payload, true)
    );
  }
}
