@if ((appointmentsBundleData$ | async)?.length > 0) {
  @for (item of appointmentsBundleData$ | async; track item; let i = $index) {
    <div class="col-12 p0">
      <app-appointments-property-item
        [applicationId]="item.applicationId"
        [property]="item.property"
        [appointments]="
          filterAppointments(item.appointments, item.appointmentAcceptances)
        "
        [appointmentsItemLoading]="guestAppointmentsLoading$ | async"
        [appointmentAcceptances]="item.appointmentAcceptances"
        [appointmentInvitations]="item.appointmentInvitations"
        [showCustomerInfo]="false"
        (selectAppointment)="onSelectAppointment($event)"
        (cancelAppointment)="onCancelAppointment($event)"
        (exportToCalendar)="onExportToCalendar($event)"
      ></app-appointments-property-item>
    </div>
  }
} @else {
  <app-no-content
    class="col-12 mb-4"
    headline="{{ 'appointment.no_data_headline_l' | translate }}"
    message="{{ 'appointment.no_data_message_l' | translate }}"
  >
  </app-no-content>
}
