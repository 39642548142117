import { HttpClientModule } from '@angular/common/http';
import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { KeycloakTenantWebService } from 'libs/infrastructure/keycloak-authentication-module/services/keycloak-tenant-web.service';
import { ModuleConfig } from './model';
import { KEYCLOAK_OPTIONS } from './contant';
import { DeepLinkService } from './services';

@NgModule({
  imports: [CommonModule]
})
export class KeycloakTenantAuthenticationModule {
  private keycloakWebService = inject(KeycloakTenantWebService);
  private infrastructure = inject(INFRASTRUCTURE_CONFIG);

  constructor() {
    void this.keycloakWebService.init();
  }

  public static forRoot(
    config = new ModuleConfig()
  ): ModuleWithProviders<KeycloakTenantAuthenticationModule> {
    return {
      ngModule: KeycloakTenantAuthenticationModule,
      providers: [
        HttpClientModule,
        { provide: KEYCLOAK_OPTIONS, useValue: config.keycloakConfig },
        KeycloakTenantWebService,
        DeepLinkService,
        JwtHelperService
      ]
    };
  }
}
