import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ApplicantStatus,
  CookiePreference,
  CustomerBranding,
  CustomQuestionResponse,
  GenericFormGroup,
  GuestApplication,
  GuestApplicationErrorState,
  NameValue,
  PropertyBean,
  QuestionResponseContainer,
  User
} from '@ui/shared/models';
import {
  HierarchicalQuestionService,
  UserConversionService
} from '@ui/legacy-lib';
import { ThemeService } from '@ui/legacy-lib';

import * as fromAppState from 'tenant-pool/+state';
import { GuestFormService } from 'tenant-pool/auth/guest-registration/services/guest-form.service';
import { GuestFormValues } from 'tenant-pool/auth/models';
import { ENVIRONMENT_CONFIG, UrlUtilService } from 'tenant-pool/core';
import { GuestAction, UserData } from 'tenant-pool/models';
import { isSalesObject } from '@ui/legacy-lib';
import {
  ConfirmReasonComponent,
  CookieBannerComponent,
  InfoBoxComponent,
  LoadingSpinnerComponent,
  PropertyBannerComponent
} from '@ui/legacy-lib';
import { AsyncPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { CustomerInfoLegacyComponent } from 'tenant-pool/components/customer-info-legacy/customer-info-legacy.component';
import { AddressPipe, ImageSizePipe } from '@ui/legacy-lib';
import { SvgIconComponent } from 'angular-svg-icon';
import { ProfileSummaryComponent } from 'tenant-pool/components/profile-summary/profile-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@ui/legacy-lib';
import {
  FetchConstants,
  getConstants,
  getCookiesPreference,
  Go,
  InitCookiesPreference,
  OpenCookieSettingsModal,
  SetCookiesPreference
} from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-guest-mode-landing',
  templateUrl: './guest-mode-landing.component.html',
  styleUrls: ['./guest-mode-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CookieBannerComponent,
    AsyncPipe,
    CustomerInfoLegacyComponent,
    RouterOutlet,
    ConfirmReasonComponent,
    PropertyBannerComponent,
    InfoBoxComponent,
    AddressPipe,
    SvgIconComponent,
    ProfileSummaryComponent,
    LoadingSpinnerComponent,
    TranslateModule,
    ButtonComponent,
    RouterLink,
    CurrencyPipe,
    ImageSizePipe,
    DecimalPipe
  ],
  standalone: true
})
export class GuestModeLandingComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private themeService = inject(ThemeService);
  private guestFormService = inject(GuestFormService);
  private userConversionService = inject(UserConversionService);
  private hierarchicalQuestionService = inject(HierarchicalQuestionService);
  private urlUtilService = inject(UrlUtilService);
  private environment = inject(ENVIRONMENT_CONFIG);

  public processing$: Observable<boolean>;
  public deleted$: Observable<boolean>;
  public error$: Observable<Error>;
  public cookiesPreference: CookiePreference;

  public property: PropertyBean;
  public application: GuestApplication;
  public customerBranding: CustomerBranding;
  public token: string;

  public userDataForm: GenericFormGroup<GuestFormValues>;
  public userData: UserData;
  public user: User;
  public customQuestionResponses: CustomQuestionResponse[];
  public questionResponseContainer: QuestionResponseContainer;

  public isDelete: boolean;
  public reasonTypeModel: string;
  public otherReasonText: string;
  public reasons: NameValue[];

  public get image() {
    return this.property?.data?.attachments?.filter(item => !!item?.url)[0];
  }

  public get customerName() {
    return this.property?.customer?.name;
  }

  public get customerLogo() {
    return this.customerBranding?.logo?.url || this.property?.customer?.logo;
  }

  public get getLogoRedirectUrl() {
    return this.urlUtilService.getLogoRedirectUrl(
      this.customerBranding?.logoRedirectUrl
    );
  }

  public get isMobile() {
    return this.environment?.mobile;
  }

  public get isSales(): boolean {
    return isSalesObject(this.property?.marketingType);
  }

  public ngOnInit() {
    this.token = this.route.snapshot.queryParams.token || '';
    const action = this.route.snapshot.queryParams.action || '';

    this.store.dispatch(new FetchConstants());

    if (action === GuestAction.DELETE) {
      this.store
        .select(getConstants)
        .pipe(
          filter(constants => !!constants),
          untilDestroyed(this)
        )
        .subscribe(constants => (this.reasons = constants.refusalReasonTypes));
      this.isDelete = true;
    }

    this.userDataForm = this.guestFormService.getForm();

    this.store.dispatch(new fromAppState.LoadGuestApplication(this.token));
    this.store.dispatch(new fromAppState.LoadGuestBranding(this.token));
    // We do not show responses to the guest, so for now we do not load responses at all to avoid error toast
    // this.store.dispatch(new fromAppState.LoadGuestCustomQuestionResponses(this.token));
    this.store.dispatch(
      new fromAppState.LoadGuestHierarchicalQuestionsResponses(this.token)
    );

    this.error$ = this.store.select(fromAppState.getGuestApplicationError);

    this.store
      .select(fromAppState.getGuestQuestionContainer)
      .pipe(untilDestroyed(this))
      .subscribe(questionResponseContainer => {
        this.questionResponseContainer = questionResponseContainer;
      });

    this.processing$ = combineLatest([
      this.store.select(fromAppState.getGuestApplicationLoading),
      this.store.select(fromAppState.getGuestBrandingLoading),
      this.store.select(fromAppState.getGuestRegisterLoading),
      this.store.select(fromAppState.guestDeleteLoading)
    ]).pipe(map(list => list.some(item => item)));

    this.store
      .select(fromAppState.getGuestBranding)
      .pipe(
        filter(item => !!item),
        untilDestroyed(this)
      )
      .subscribe(branding => {
        this.customerBranding = branding;
        this.themeService.createTheme(branding);
      });

    this.store
      .select(fromAppState.getGuestCustomQuestionResponses)
      .pipe(
        filter(item => !!item),
        untilDestroyed(this)
      )
      .subscribe(customQuestionResponses => {
        this.customQuestionResponses = customQuestionResponses;
      });

    this.store
      .select(fromAppState.getGuestApplication)
      .pipe(
        filter(item => !!item),
        untilDestroyed(this)
      )
      .subscribe(application => {
        this.application = application;
        this.property = application.property || {};
        this.userData = this.getUserDataFromApplication(application);
        this.guestFormService.patchForm(application);
        this.user = this.userConversionService.convertUserData(this.userData);

        // initially load the preference from LS and enable tracking if configured
        this.store.dispatch(new InitCookiesPreference(this.user));

        // TODO refactor: only redirect to current state if clicking on first email to overview page.
        //  So we can still get to other states by clicking on other email links, like the email for
        //  viewing appointments or intent.
        if (
          !this.isDelete &&
          window.location.toString().indexOf('/guest?') > -1
        ) {
          this.redirectToCurrentState(application?.applicantStatus);
        }
      });

    this.store
      .select(getCookiesPreference)
      .pipe(
        filter(cookiesPreference => !!cookiesPreference),
        untilDestroyed(this)
      )
      .subscribe(
        cookiesPreference => (this.cookiesPreference = cookiesPreference)
      );
  }

  public backToApp() {
    this.themeService.setTheme(this.themeService.getDefaultTheme);
  }

  public deleteApplication() {
    const payload = {
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    };
    this.store.dispatch(new fromAppState.DeleteGuest(payload, this.token));
    this.deleted$ = this.store.select(fromAppState.guestDeleteDone);
  }

  public getErrorMessage(errorMsg: string) {
    if (
      errorMsg === GuestApplicationErrorState.USER_PROFILE_NOT_GUEST_L ||
      errorMsg === GuestApplicationErrorState.APPLICATION_NOT_FOUND_L ||
      errorMsg === GuestApplicationErrorState.TOKEN_IS_EXPIRED_L
    ) {
      return errorMsg;
    }
    return 'GUEST_MODE_UNKNOWN_ERROR';
  }

  public errorShowLogin(errorMsg: string) {
    return errorMsg === GuestApplicationErrorState.USER_PROFILE_NOT_GUEST_L;
  }

  public onLogin(email?: string) {
    this.store.dispatch(
      new Go({
        path: ['login'],
        extras: { queryParams: { email } }
      })
    );
  }

  public register() {
    const payload = {
      ...this.application,
      questionResponses:
        this.hierarchicalQuestionService.getHierarchicalRootQuestionPayload(
          this.questionResponseContainer.questions.map(r => r.rootQuestion)
        )
    };
    this.store.dispatch(
      new fromAppState.OpenGuestRegisterModal(payload, this.token)
    );
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new SetCookiesPreference(payload, this.user));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(new OpenCookieSettingsModal(payload, true, this.user));
  }

  private redirectToCurrentState(state: string) {
    switch (state) {
      case ApplicantStatus.INVITED_TO_VIEWING: {
        this.redirect('guest/viewing', this.token);
        break;
      }
      case ApplicantStatus.DECLARE_INTENT: {
        this.redirect('guest/intent', this.token, 'INTENT');
        break;
      }
    }
  }

  private redirect(route: string, token: string, value?: string) {
    this.store.dispatch(
      new Go({
        path: [route],
        extras: {
          queryParams: { token, value }
        }
      })
    );
  }

  private getUserDataFromApplication(application: GuestApplication) {
    const { userProfile } = application;
    const { email, data, address } = userProfile;
    const { firstname, name } = data;

    return {
      firstName: firstname,
      lastName: name,
      email,
      password: null,
      confirmPassword: null,
      ...address,
      socialLogin: false
    };
  }
}
