import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  AppInputDirective,
  FormFieldComponent,
  FormFieldLabelComponent,
  LoadingSpinnerComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@ui/legacy-lib';

@Component({
  selector: 'app-apply-sales-modal',
  templateUrl: './apply-sales-modal.component.html',
  styleUrls: ['./apply-sales-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalV2Component,
    ModalContentComponent,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective,
    LoadingSpinnerComponent,
    ModalFooterComponent,
    ButtonComponent
  ],
  standalone: true
})
export class ApplySalesModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  @Input() propertyId: string;
  @Input() form: FormGroup;

  public close(): void {
    this.ngbActiveModal.close();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public apply() {
    const { email, ...profileData } = this.form.value;
    const payload = {
      profileData,
      email,
      propertyId: this.propertyId
    };
    this.ngbActiveModal.close(payload);
  }
}
