import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  customEmailValidator,
  getRedirectUrl,
  Go,
  LocalStorageService,
  ModalContentComponent,
  ModalService,
  ModalV2Component,
  RegisterCardComponent,
  SessionStorageService,
  UserLogin,
  WINDOW_REF
} from '@ui/legacy-lib';
import { Property, SocialLogin, StateAfterAuth } from '@ui/shared/models';

import { filter, map } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AsyncPipe } from '@angular/common';
import { UrlUtilService } from '../../core';
import { MainPageNavigation, storageKeys } from '../../config';
import { SocialLoginConfirmationModalComponent } from '../../components/social-login-confirmation-modal/social-login-confirmation-modal.component';
import {
  getRegisterDone,
  getRegisterError,
  getRegisterProcessing,
  Register
} from '../../+state';

@UntilDestroy()
@Component({
  selector: 'app-portal-register-modal',
  templateUrl: './portal-register-modal.component.html',
  styleUrls: ['./portal-register-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalV2Component,
    ModalContentComponent,
    RegisterCardComponent,
    AsyncPipe
  ]
})
export class PortalRegisterModalComponent implements OnInit {
  readonly property = input<Property>(undefined);
  readonly socialLogin = input<SocialLogin>(undefined);
  public form: FormGroup;
  public registerProcessing$: Observable<boolean>;
  public registerError$: Observable<string>;
  private ngbActiveModal = inject(NgbActiveModal);
  private formBuilder = inject(FormBuilder);
  private urlHelperService = inject(UrlUtilService);
  private store = inject(Store);
  private localStorageService = inject(LocalStorageService);
  private sessionStorageService = inject(SessionStorageService);
  private modalService = inject(ModalService);
  private windowRef = inject(WINDOW_REF);

  public get getLogoRedirectUrl() {
    return this.urlHelperService.getLogoRedirectUrl(
      this.property()?.branding?.logoRedirectUrl
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', Validators.required],
      email: [
        '',
        Validators.compose([Validators.required, customEmailValidator])
      ],
      acceptPolicies: [null, Validators.requiredTrue],
      optInProspect: [false]
    });

    this.registerProcessing$ = this.store.select(getRegisterProcessing);
    this.registerError$ = this.store
      .select(getRegisterError)
      .pipe(map(err => err && err.message));

    this.store
      .select(getRegisterDone)
      .pipe(
        untilDestroyed(this),
        filter(done => done)
      )
      .subscribe(() => this.closeModal());

    const personalInformation = this.sessionStorageService.getItem(
      storageKeys.personalInformation
    );

    const socialLogin = this.socialLogin();
    if (socialLogin?.isSocialLogin) {
      if (this.form) {
        this.form.get('password').disable();
        this.form.get('email').disable();
      }
      this.patchForm(socialLogin);
    } else if (personalInformation) {
      this.patchForm(personalInformation);
    }
  }

  public onRegister() {
    const userData = {
      brandedCustomerId: this.property()?.customer?.id,
      ...this.form.value
    };

    const socialLogin = this.socialLogin();
    if (socialLogin?.isSocialLogin) {
      userData.email = socialLogin.email;
      userData.password = '';
      userData.socialLogin = socialLogin.isSocialLogin;
    }

    this.store.dispatch(new Register(userData));

    // clean up (removeItem) might be unecessary
    this.sessionStorageService.removeItem(storageKeys.personalInformation);
    this.localStorageService.removeItem(storageKeys.landlordThemeToken);
    this.localStorageService.setItem(storageKeys.portalRegistration, true);
  }

  public onLoginRedirect() {
    this.store.dispatch(new Go({ path: [MainPageNavigation.LOGIN] }));
    this.closeModal();
  }

  public onSocialLogin($event: string) {
    let pathAfterAuth: string;

    const property = this.property();
    if (property) {
      pathAfterAuth = `apply/${property.id}`;
    } else {
      pathAfterAuth = 'auth/portal-register';
    }

    const stateAfterAuth: StateAfterAuth = {
      pathAfterAuth,
      queryParams: {
        socialLoginType: $event,
        socialLogin: true
      }
    };

    const redirectUri = getRedirectUrl(
      this.windowRef.location.toString(),
      '/auth',
      stateAfterAuth
    );

    const socialLoginConfirmation = this.modalService.open(
      SocialLoginConfirmationModalComponent,
      { centered: true }
    );
    socialLoginConfirmation.onClose().subscribe(() => {
      this.store.dispatch(
        new UserLogin({
          redirectUri,
          loginMethod: $event
        })
      );
    });

    this.localStorageService.removeItem('landlordThemeToken');
  }

  public closeModal() {
    this.ngbActiveModal.close();
  }

  private patchForm(data) {
    if (this.form && this.form.get('firstName').value === '') {
      this.form.get('firstName').patchValue(data.firstName);
    }
    if (this.form && this.form.get('lastName').value === '') {
      this.form.get('lastName').patchValue(data.lastName || data.name);
    }
    if (this.form && this.form.get('email').value === '') {
      this.form.get('email').patchValue(data.email);
    }
  }
}
